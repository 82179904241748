import { debounce, DebouncedFunc } from 'lodash';
import Range from 'rc-slider/lib/Range';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ChevronDown, ChevronUp } from '@videoblocks/react-icons';

import { setAudioFiltersChanged } from '../../../../Audio/actions/AudioActions';
import Collapse from '../../../../common/components/Collapse/Collapse';
import {
  convertSecondsToMMSS,
  roundUpToClosestMinute,
} from '../../../../common/utils';
import { searchOrigins } from '../../../Shared/constants';
import { updateSearchOptionsAndRefresh } from '../../actions/SearchActions';
import { SelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import { selectSearchFilterOptions } from '../../selectors/searchSelectors';

import './SliderFilter.less';
import 'rc-slider/assets/index.css';

type Props = {
  minRangeValue: number;
  maxRangeValue: number;
  stepInterval?: number;
  name: string;
  isCollapsed?: boolean;
  toggleCollapsed: () => void;
  minValue?: number;
  maxValue?: number;
  selectedSearchFilterOptions: SelectedSearchFilterOptions;
  setAudioFiltersChanged: typeof setAudioFiltersChanged;
  updateSearchOptionsAndRefresh: typeof updateSearchOptionsAndRefresh;
};

type State = {
  sliderOneValue: number;
  sliderTwoValue: number;
};

class DurationSliderFilter extends Component<Props, State> {
  calculatedMaxRangeInSeconds: number;
  calculatedMinRangeInSeconds: number;
  fireSearch: DebouncedFunc<
    (newSearchOptions: SelectedSearchFilterOptions) => void
  >;

  constructor(props) {
    super(props);

    this.calculatedMaxRangeInSeconds = this.nextClosestMinuteInSeconds(
      props.maxRangeValue
    );
    this.calculatedMinRangeInSeconds = this.nextClosestMinuteInSeconds(
      props.minRangeValue
    );

    this.state = {
      sliderOneValue: props.minValue || this.calculatedMinRangeInSeconds,
      sliderTwoValue: props.maxValue || this.calculatedMaxRangeInSeconds,
    };

    this.fireSearch = debounce((newSearchOptions) => {
      this.props.setAudioFiltersChanged(true);
      this.props.updateSearchOptionsAndRefresh(newSearchOptions);
    }, 100);
  }

  static defaultProps = {
    stepInterval: 1,
  };

  componentDidUpdate(prevProps) {
    const { minDuration, maxDuration } = this.props.selectedSearchFilterOptions;
    const { minDuration: prevMinDuration, maxDuration: prevMaxDuration } =
      prevProps.selectedSearchFilterOptions;

    if (prevMinDuration !== minDuration || prevMaxDuration !== maxDuration) {
      this.setNewValues([minDuration, maxDuration]);
    }
  }

  nextClosestMinuteInSeconds(secs) {
    return roundUpToClosestMinute(secs) * 60;
  }

  setNewValues = (values) => {
    this.setState({
      sliderOneValue:
        values[0] || this.nextClosestMinuteInSeconds(this.props.minRangeValue),
      sliderTwoValue:
        values[1] || this.nextClosestMinuteInSeconds(this.props.maxRangeValue),
    });
  };

  grabNewSearchResults = (values) => {
    const { selectedSearchFilterOptions } = this.props;
    const { minDuration, maxDuration } = selectedSearchFilterOptions;

    const slOneValue = values[0];
    const slTwoValue = values[1];
    const max =
      slTwoValue >= this.calculatedMaxRangeInSeconds ? null : slTwoValue;
    const min =
      slOneValue <= this.calculatedMinRangeInSeconds ? null : slOneValue;

    if (slOneValue !== minDuration || max !== maxDuration) {
      const newSearchOptions = selectedSearchFilterOptions.update({
        minDuration: min,
        maxDuration: max,
        page: 1,
        isPagination: false,
        searchOrigin: searchOrigins.FILTERS,
      }); // Go back to page 1 when any filter is changed

      this.fireSearch(newSearchOptions);
    }
  };

  render() {
    const {
      name,
      minRangeValue,
      maxRangeValue,
      isCollapsed,
      toggleCollapsed,
      stepInterval,
    } = this.props;
    const {
      sliderOneValue = this.calculatedMinRangeInSeconds,
      sliderTwoValue = this.calculatedMaxRangeInSeconds,
    } = this.state;

    const htmlSafeName = this.getHtmlSafeName(name);

    return (
      <div
        id={`filter-group-${htmlSafeName}`}
        className={isCollapsed ? 'collapsed' : 'expanded'}
      >
        <h3
          className="heading"
          onClick={toggleCollapsed}
          onKeyDown={toggleCollapsed}
        >
          {name}
          {this.getArrowIcon(isCollapsed)}
        </h3>

        <Collapse isCollapsed={isCollapsed} controlId={htmlSafeName}>
          <div className="slider-container">
            <Range
              className="duration-slider"
              min={minRangeValue}
              max={maxRangeValue}
              step={stepInterval}
              defaultValue={[minRangeValue, maxRangeValue]}
              value={[sliderOneValue, sliderTwoValue]}
              onChange={(values) => this.setNewValues(values)}
              onAfterChange={(values) => this.grabNewSearchResults(values)}
              marks={{
                [minRangeValue]: {
                  style: { left: '3%' },
                  label: convertSecondsToMMSS(
                    sliderOneValue,
                    this.calculatedMaxRangeInSeconds
                  ),
                },
                [maxRangeValue]: {
                  style: { left: '96%' },
                  label: convertSecondsToMMSS(
                    sliderTwoValue,
                    this.calculatedMaxRangeInSeconds
                  ),
                },
              }}
            />
          </div>
        </Collapse>

        {isCollapsed &&
          (sliderOneValue > minRangeValue ||
            sliderTwoValue < maxRangeValue) && (
            <div className="collapsible-filter-selected-option">
              {convertSecondsToMMSS(sliderOneValue)} to{' '}
              {convertSecondsToMMSS(sliderTwoValue)}
            </div>
          )}
      </div>
    );
  }

  getHtmlSafeName(rawName) {
    return rawName.replace(/\W/g, '');
  }

  getArrowIcon(isCollapsed) {
    if (isCollapsed) {
      return <ChevronDown className="w-3 h-3 m-1.5" />;
    } else {
      return <ChevronUp className="w-3 h-3 m-1.5" />;
    }
  }
}

const mapStateToProps = (state) => ({
  selectedSearchFilterOptions: selectSearchFilterOptions(state),
});

export default connect(mapStateToProps, {
  setAudioFiltersChanged,
  updateSearchOptionsAndRefresh,
})(DurationSliderFilter);
