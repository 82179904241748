import React from 'react';

import AppliedFilter from './AppliedFilter';

interface Props {
  hasVocals: string;
  onClear: () => void;
  className?: string;
}

const Vocals = ({ hasVocals, onClear, className }: Props) => {
  if (hasVocals !== '') {
    const label = hasVocals ? 'Vocals/Lyrical' : 'No Vocals/Instrumental';

    return (
      <AppliedFilter
        label={label}
        onClear={onClear}
        secondaryClassName="Vocals"
        className={className}
      />
    );
  } else {
    return null;
  }
};

export default Vocals;
