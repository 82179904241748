import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectSearchFilterOptions } from '../selectors/searchSelectors';
import { SearchHistoryState } from '../utils/searchOptionsToLocation/types';

export default function useCaptureInitialAudioSearchOptionsAsHistoryState(): void {
  const {
    replace,
    location: { pathname, search },
  } = useHistory<SearchHistoryState>();
  const searchFilterOptions = useSelector(selectSearchFilterOptions);

  // There are a couple aspects of search filter options that are not serialized within the URL.
  // - Artists: only artist ids are in the URL, but we need the name for display.
  // - Similar search stock item: only the id is in the URL, but we need the title for display.
  // Upon mounting we want to see if any of the above is present and stash it into history state.
  // This allows us to restore this information if the user hits the back button.
  // We only need to do this on mount because further dynamic searches have this information captured imperatively.
  useEffect(() => {
    const artists = searchFilterOptions?.artists;
    const searchSimilarTitle = searchFilterOptions?.searchSimilarTitle;
    const newState = {} as SearchHistoryState;

    if (artists?.length > 0) {
      newState.artists = artists;
    }
    if (searchSimilarTitle) {
      newState.searchSimilarTitle = searchSimilarTitle;
    }

    if (Object.keys(newState).length > 0) {
      replace(pathname + search, newState);
    }
  }, []);
}
