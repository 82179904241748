import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { initiateFetchWorkflow } from '../actions/SearchActions';
import SearchOptions from '../entities/SearchOptions';
import useAudioSearchOptionsFromLocation from '../hooks/useAudioSearchOptionsFromLocation';
import useCaptureInitialAudioSearchOptionsAsHistoryState from '../hooks/useCaptureInitialAudioSearchOptionsAsHistoryState';
import {
  audioSearchContentTypeToKeys,
  calculateAppliedFilterNums,
} from '../utils/searchUtils';
import AudioMenuContainer from './AudioMenuContainer';
import {
  AudioSelectedSearchFilterOptions,
  SelectedSearchFilterOptions,
} from './MenuContainerInterfaces';
import SearchContainer from './SearchContainer';
import AudioAppliedFilters from './appliedFilters/AudioAppliedFilters';

const AudioSearchContainer = (): JSX.Element => {
  useCaptureInitialAudioSearchOptionsAsHistoryState();

  const searchOptions = useAudioSearchOptionsFromLocation();

  const searchOptionsRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchOptionsRef.current) {
      dispatch(initiateFetchWorkflow(searchOptions));
    }
    searchOptionsRef.current = searchOptions;
  }, [searchOptions]);

  const getFilterTotal = (
    selectedSearchFilterOptions: SelectedSearchFilterOptions<AudioSelectedSearchFilterOptions>
  ) => {
    return calculateAppliedFilterNums(
      selectedSearchFilterOptions,
      audioSearchContentTypeToKeys,
      SearchOptions.getDefaultSearchOptions()
    );
  };

  return (
    <SearchContainer
      renderAppliedFilters={() => <AudioAppliedFilters />}
      menuContainer={AudioMenuContainer}
      calculateAppliedFilterNums={getFilterTotal}
    />
  );
};

export default AudioSearchContainer;
