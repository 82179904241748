import React from 'react';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import AppliedFilter from './AppliedFilter';

interface Props {
  tempoMin: number;
  tempoMax: number;
  highestTempo: number;
  onClear: () => void;
  className?: string;
}

const Tempo = ({ tempoMin, tempoMax, onClear, className }: Props) => {
  const highestTempo = SiteConstants.getInstance().getMaxTempo();

  if ((tempoMin && tempoMin > 0) || (tempoMax && tempoMax < highestTempo)) {
    const formattedTempoMin = tempoMin
      ? tempoMin
      : SiteConstants.getInstance().getMinTempo();
    const formattedTempoMax = tempoMax
      ? tempoMax >= highestTempo
        ? `${tempoMax}+`
        : tempoMax
      : highestTempo;
    const label = `${formattedTempoMin} - ${formattedTempoMax} BPM`;

    return (
      <AppliedFilter
        label={label}
        onClear={onClear}
        secondaryClassName="Tempo"
        className={className}
      />
    );
  } else {
    return null;
  }
};

export default Tempo;
